<template>
    <DxList
        :data-source="{
            store: DanhSachLaiXe,
            paginate: true,
            pageSize: 10,
        }"
        height="calc(100vh - 96px)"
        page-load-mode="nextButton"
        key-expr="id"
        :hoverStateEnabled="false"
        :focusStateEnabled="false"
        :activeStateEnabled="false"
        no-data-text="Không có dữ liệu!"
        class="list-lenh-style"
    >
        <!-- style="margin-top: calc(36px + 48px)" -->
        <template #item="{ data: item }">
            <div>
                <!-- Bổ sung div để sửa lỗi khi build bị vỡ giao diện -->
                <FrameVue :item="item" @getData="getData" />
            </div>
            <!-- :key="`${componentKey}-1`" -->
        </template>
    </DxList>
</template>

<script>
import DxList from "devextreme-vue/list";
import FrameVue from "./Frame.vue";
// import PopupFilterVue from "../Popup/PopupFilter.vue";
export default {
    components: {
        DxList,
        FrameVue,
    },
    props: {
        DanhSachLaiXe: {
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
        getData() {
            this.$emit("getData");
        },
    },
    mounted() {},
};
</script>

<style scoped>
/* >>> .dxList-custom {
    padding: 10px;
} */
</style>
